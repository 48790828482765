.bold_font {
  font-family: Montserrat-Bold;
}

.medium_font {
  font-family: Montserrat-Medium;
}

.regular_font {
  font-family: Montserrat-Regular;
}
.semi_bold_font {
  font-family: Montserrat-SemiBold;
}

body {
  font-family: Montserrat-Regular;
  color: $darkTextColor;
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}
.err-msg {
  color: $error;
}
.MuiDrawer-paper {
  background: linear-gradient(180deg, #803240 0%, #01172f 100%);
  color: white !important;
  .MuiListItemIcon-root {
    color: white !important;
  }
}

.MuiExpansionPanelSummary-content {
  margin: 0px !important;
}
.MuiExpansionPanelDetails-root {
  padding: 8px 24px !important;
}
button.btn-primary {
  font-family: Montserrat-Bold;
  background-color: $secondaryColor !important;
  border: 0;
  border-color: 0 !important;
  border-radius: 28px;
}

.borderRaduis {
  outline: none !important;
  border-radius: 28px !important;
}

.MuiTypography-body1 {
  font-family: inherit !important;
}

.MuiExpansionPanelDetails-root {
  flex-wrap: wrap;
}

.card_style {
  border-radius: 8px;
  border: 0;
  box-shadow: 0px 2px 11px 0px rgba(194, 194, 194, 0.7);
}
.MuiTableCell-body {
  font-family: Montserrat-Regular;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 2px solid #7f323f !important;
}

.width-75 {
  width: 75%;
}

.MuiButton-outlinedPrimary {
  border: 2px solid #7f323f !important;
  &:hover {
    border: 2px solid #7f323f !important;
  }
}

.MuiButton-outlinedSecondary {
  border: 2px solid $secondaryColor !important;
  &:hover {
    border: 2px solid $secondaryColor !important;
  }
}

.MuiButton-root {
  font-weight: 600 !important;
  text-transform: capitalize !important;
  font-family: Montserrat-Medium !important;
}

#alert-dialog-description {
  font-family: Montserrat-Regular;
}

.page-item.active .page-link {
  border-color: $secondaryColor;
  background-color: $secondaryColor;
  font-family: Montserrat-Medium;
}

.page-item .page-link {
  color: $primaryColor;
}

.arabic_font {
  font-family: NeoSansArabic;
}

button:focus {
  outline: 0 !important;
}

.MuiFormControl-root {
  .MuiFormLabel-root.MuiInputLabel-outlined {
    background-color: WHITE;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.fill_all {
  color: #f44336;
}
div[role="tooltip"] {
  z-index: 999999;
}

.MuiMenuItem-root {
  font-family: Montserrat-Medium !important;
}

.light_bg_color {
  background-color: lightgray !important;
}
@media only screen and (max-width: 400px) {
  .width-75 {
    width: 100%;
  }
}

.primary-btn {
  color: white;
  background-color: $primaryColor;
  padding: 8px 13px;
  border: none;
  border-radius: 13px;
  font-weight: bold;
}

.secondary-btn {
  color: $primaryColor;
  background-color: white;
  padding: 6px 13px;
  border: 1px solid $primaryColor;
  border-radius: 13px;
  font-weight: bold;
}

button {
  outline: none !important;
}

.rotate-Y-180 {
  transform: rotateY(180deg);
}

.txt-white {
    color: $white;
}

.txt-dark { 
  color : $darkPrimaryColor;
}

.border_secondary {
  border-color: $secondaryColor !important;
  &:hover {
    background-color: rgba(177, 58, 78, 0.03) !important;
    border-color: $secondaryColor !important;
  }
}

.txt-primary {
    color: $primaryColor;
}
.loading-indicator:before {
  content: '';
  background: #000000cc;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999999999999;
}

.loading-indicator:after {
  content: "\f1ce";
  font-family: FontAwesome;
  position: fixed;
  width: 100%;
  top: 50%;
  left: 0;
  z-index: 99999999999999;
  color:white;
  text-align:center;
  font-weight:100;
  font-size:4rem;       
  -webkit-animation: fa-spin 1s infinite linear;
  animation: fa-spin 1s infinite linear; 
}
.langBtn {
  color: $primaryColor;
  text-decoration: none;
  font-family: NeoSansArabicBold;
}

.langBtnAlign {
  text-align: right;
}
.no-data-found{
  color:$primaryColor;
  @extend .semi_bold_font;
  font-size:2em;
  text-align: center;
}

.MuiFormHelperText-root{
  font-family: Montserrat-Medium;
}

.react-tel-input{
  margin-bottom: 0px;
}

.word-break {
  word-break: break-all;
}

#offer_template_container .body {
  text-align: inherit;
  background-color: transparent;
}
.white-space-perline {
  white-space: pre-line;
}
.error-message {
  color: red;
  padding-inline-start: 10px;
  font-size: 12px;
}
.cover_image {
  width: auto;
  height: 25em;
  max-width: 600px;
  max-height: 400px;
  margin: auto;
  display: block;
}
.break-word {
  overflow-wrap: break-word;
}
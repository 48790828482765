.rtl {
  text-align: right;
  font-family: NeoSansArabic;
  .bold_font {
    font-family: NeoSansArabicBold;
  }

  .medium_font , .semi_bold_font {
    font-family: NeoSansArabicMedium;
  }

  .regular_font {
    font-family: NeoSansArabic;
  }
  .MuiTableCell-body {
    font-family: NeoSansArabic;
  }
  .MuiFormHelperText-root{
    font-family: NeoSansArabicMedium;
  }
  .MuiListItem-button {
    &:hover {
      border-right: 2px solid white;
      border-left: none;
      background: linear-gradient(
        80deg,
        rgba(74, 144, 226, 0) 0%,
        rgba(255, 255, 255, 0.19) 100%
      );
    }
  }
  .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover {
    border-right: 2px solid white;
    border-left: none;
    background: linear-gradient(
      80deg,
      rgba(74, 144, 226, 0) 0%,
      rgba(255, 255, 255, 0.19) 100%
    );
  }
  .form-container {
    margin-right: auto !important;
    margin-left: 0em !important;
    .langBtnAlign {
      text-align: left;
    }
  }
  .help-tip {
    left: 25px;
    right: auto;
    &:hover ul {
      text-align: right !important;
      left: 0 !important;
      right: auto !important;
    }
  }
  .MuiButton-root {
    font-family: NeoSansArabicMedium !important;
  }

  #alert-dialog-description {
    font-family: NeoSansArabic !important;
  }
  .carsoul-margin {
    margin-left: auto;
    margin-right: 10%;
  }

  .owl-carousel {
    direction: ltr;
    transform: rotateY(180deg);
    .single-card-wrapper {
      transform: rotateY(-180deg);
      direction: rtl;
    }
  }
}

.MuiDialog-paper[dir="rtl"] {
  text-align: right;
}

.MuiMenuItem-root[dir="rtl"] {
  font-family: NeoSansArabic !important;
}

body[dir="rtl"] {
  text-align: right;
  font-family: NeoSansArabic !important;
  .bold_font {
    font-family: NeoSansArabicBold;
  }
  .medium_font , .semi_bold_font {
    font-family: NeoSansArabicMedium;
  }
  .regular_font {
    font-family: NeoSansArabic;
  }
}

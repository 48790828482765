/* ============= Colors ============= */
:root {
  --primary-color: #00000000;
  --secondary-color: #00000000;
  --darkTextColor: rgba(34, 42, 65, 1);
  --dark-primary-color: rgba(63, 23, 30, 1);
  --secondary-color-transparent: rgb(128, 128, 128, .2);
}
$primaryColor: var(--primary-color);
$secondaryColor: var(--secondary-color);
$darkTextColor: var(--dark-text-color);
$darkPrimaryColor: (--dark-primary-color);
$lightGrayColor: rgba(109, 119, 131, .18);
$lightTextColor: rgba(109, 119, 131, 1);
$lightBorderColor: rgba(151, 151, 151, 0.5);
$dimGray: rgba(110, 110, 110, 1);
$error: #ff3739;
$success: #3bd35f;
$white: #ffffff;
/* ============= Fonts ============= */
$fontExtentions: "eot", "ttf", "woff", "woff2";
$fontFamilies: "Montserrat-Bold", "Montserrat-Medium", "Montserrat-Regular",
  "Montserrat-SemiBold", "Montserrat-Black";
$ArabicFontFamilies: "NeoSansArabic", "NeoSansArabicBold", "NeoSansArabicMedium";

/**
STORED COLORS
    primary_color: "#87364e", rgb(127, 50, 63)
    secondary_color: "#ea8685"
**/
